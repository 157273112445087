import React from 'react'
import PropTypes from 'prop-types'
import { StyledSpinner } from './styles'

const Spinner = ({ size, color }) => (
  <StyledSpinner viewBox="0 0 50 50" size={size} color={color}>
    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="2" />
  </StyledSpinner>
)

export default Spinner

Spinner.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}
