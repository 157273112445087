import styled from 'styled-components'

export const StyledLoadingEllipsis = styled.span`
  font-size: ${(props) => (props.size ? `${props.size}px` : '16px')};
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`
