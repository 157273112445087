import styled from 'styled-components'
import { Link } from 'gatsby'

import { breakpoints } from 'src/utils/styles/global-styles'

export const Container = styled.div`
  border: 1px solid red;
`

export const TableRow = styled.tr`
  border-bottom: 1.5px solid rgba(68, 68, 68, 0.1);
`

export const TitleLink = styled(Link)`
  color: ${(props) => props.theme.grayDark};
  font-family: 'FilsonProBook';
  line-height: 26px;
  letter-spacing: 0.09px;
  display: block;
  text-decoration: none;
  font-size: 18px;
  @media (max-width: ${breakpoints.l}px) {
    font-size: 15px;
  }
  @media (max-width: 350px) {
    font-size: 14px;
  }
`

export const TD = styled.td`
  text-align: ${(props) => props.textAlign || 'left'};
  font-family: 'FilsonProBook';
  p {
    text-decoration: ${(props) => (props.strikethrough ? 'line-through' : 'none')};
    font-family: 'FilsonProBook';
    color: ${(props) => props.color};
    font-size: 18px;
    letter-spacing: 0.09px;
    @media (max-width: ${breakpoints.m}px) {
      font-size: 16px;
      margin-top: 0px;
    }
    @media (max-width: 350px) {
      font-size: 14px;
      column-width: 120px;
    }
  }
`

export const DesktopOnlyTD = styled(TD)`
  @media (max-width: ${breakpoints.m}px) {
    display: none;
  }
`

export const ProductInfo = styled.div``

export const Remove = styled.a`
  color: ${(props) => props.theme.coral};
  cursor: pointer;
  font-size: 13px;
  line-height: 26px;
  letter-spacing: 0.07px;
  font-weight: normal;
  &:hover {
    font-weight: bold;
  }
  @media (max-width: 350px) {
    font-size: 12px;
  }
`

export const LeftQuantityButton = styled.button`
  color: ${(props) => props.theme.grayDark};
  font-family: 'FilsonProBook';
  background: ${(props) => props.theme.cream};
  border: none;
  font-size: 18px;
  padding-left: 0px;
  visibility: ${(props) => (props.hide ? 'hidden' : 'visible')};
  @media (max-width: ${breakpoints.m}px) {
    margin-bottom: 35px;
    padding: 1rem 5px 6px 0px;
    font-size: 16px;
  }
  @media (max-width: 350px) {
    font-size: 14px;
  }
  cursor: pointer;
`
export const RightQuantityButton = styled(LeftQuantityButton)`
  padding-right: 0px;
  @media (max-width: ${breakpoints.m}px) {
    padding: 1rem 0px 1px 5px;
  }
`

export const ProductTD = styled(TD)`
  display: flex;
  align-items: center;
  > :first-child {
    margin: 1rem 1rem 1rem 0;
  }
  @media (max-width: ${breakpoints.l}px) {
    display: table-cell;
  }
`

export const LineItemTotalCtn = styled.div`
  min-width: 70px;
  text-align: 'right';
`
export const HiddenOnDesktop = styled.div`
  display: none;
  @media (max-width: ${breakpoints.m}px) {
    display: inline-block;
  }
`
export const Price = styled.p`
  text-decoration: ${(props) => (props.strikethrough ? 'line-through' : 'none')} !important;
  color: ${(props) => (props.red ? props.theme.coral : props.theme.grayDark)} !important;
  margin-bottom: 5px;
  text-align: right;
  @media (max-width: ${breakpoints.m}px) {
    padding-top: ${(props) => (props.red ? '0px' : '0.8rem')} !important;
  }
`
export const FrameWithPlusExplainer = styled.p`
  font-family: 'FilsonProBook';
  font-size: 13px !important;
  color: ${(props) => props.theme.grayLight};
  max-width: 90%;
  margin: 0;
`
export const SubTotal = styled.p`
  text-decoration: ${(props) => (props.strikethrough ? 'line-through' : 'none')};
`
