import React from 'react'
import PropTypes from 'prop-types'

import { StyledLoadingEllipsis } from './styles'

const LoadingEllipsis = ({ size }) => <StyledLoadingEllipsis size={size} />

LoadingEllipsis.propTypes = {
  size: PropTypes.number,
}

export default LoadingEllipsis
