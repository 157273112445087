import { graphql, useStaticQuery } from 'gatsby'

export const useStyleConfig = () => {
  const {
    classic,
    cover,
    wood,
    shadowBox,
    classicBlack,
    classicWhite,
    classicSage,
    classicLagoon,
    coverBlack,
    coverWhite,
    woodWalnut,
    woodBirch,
    shadowBoxAluminum,
    shadowBoxCharcoal,
    individualClassicBlack,
    individualCoverBlack,
    individualShadowBoxAluminum,
    individualWoodBirch,
    individualWoodWalnut,
    contentfulFrame2Price,
  } = useStaticQuery(graphql`
    query StyleConfigImages {
      classic: file(relativePath: { eq: "frame2/classic-style.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      cover: file(relativePath: { eq: "frame2/cover-style.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      wood: file(relativePath: { eq: "frame2/wood-style.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      shadowBox: file(relativePath: { eq: "frame2/shadowbox-style.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      classicBlack: file(relativePath: { eq: "frame2/style/classic-black.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      classicWhite: file(relativePath: { eq: "frame2/style/classic-white.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      classicSage: file(relativePath: { eq: "frame2/style/classic-sage.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      classicLagoon: file(relativePath: { eq: "frame2/style/classic-lagoon.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      coverBlack: file(relativePath: { eq: "frame2/style/cover-black.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      coverWhite: file(relativePath: { eq: "frame2/style/cover-white.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      woodBirch: file(relativePath: { eq: "frame2/style/wood-birch.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      woodWalnut: file(relativePath: { eq: "frame2/style/wood-walnut.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      shadowBoxAluminum: file(relativePath: { eq: "frame2/style/shadowbox-aluminum.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      shadowBoxCharcoal: file(relativePath: { eq: "frame2/style/shadowbox-charcoal.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      individualClassicBlack: file(
        relativePath: { eq: "frame2/style/individual-classic-black.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      individualCoverBlack: file(relativePath: { eq: "frame2/style/individual-cover-black.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      individualShadowBoxAluminum: file(
        relativePath: { eq: "frame2/style/individual-shadow-box-aluminum.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      individualWoodBirch: file(relativePath: { eq: "frame2/style/individual-wood-birch.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      individualWoodWalnut: file(relativePath: { eq: "frame2/style/individual-wood-walnut.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      contentfulFrame2Price: allContentfulFrame2AndSnapFrameStrikethrough {
        edges {
          node {
            classic_black_original_price
            classic_black_discounted_price
            classic_black_discount_bubble
            classic_white_original_price
            classic_white_discounted_price
            classic_white_discount_bubble
            classic_sage_original_price
            classic_sage_discounted_price
            classic_sage_discount_bubble
            classic_lagoon_original_price
            classic_lagoon_discounted_price
            classic_lagoon_discount_bubble
            cover_black_original_price
            cover_black_discounted_price
            cover_black_discount_bubble
            cover_white_original_price
            cover_white_discounted_price
            cover_white_discount_bubble
            wood_birch_original_price
            wood_birch_discounted_price
            wood_birch_discount_bubble
            wood_walnut_original_price
            wood_walnut_discounted_price
            wood_walnut_discount_bubble
            shadow_box_aluminum_original_price
            shadow_box_aluminum_discounted_price
            shadow_box_aluminum_discount_bubble
            shadow_box_charcoal_original_price
            shadow_box_charcoal_discounted_price
            shadow_box_charcoal_discount_bubble
            snap_classic_black_original_price
            snap_classic_black_discounted_price
            snap_classic_white_original_price
            snap_classic_white_discounted_price
            snap_classic_sage_original_price
            snap_classic_sage_discounted_price
            snap_classic_lagoon_original_price
            snap_classic_lagoon_discounted_price
            snap_cover_black_original_price
            snap_cover_black_discounted_price
            snap_cover_white_original_price
            snap_cover_white_discounted_price
            snap_wood_birch_original_price
            snap_wood_birch_discounted_price
            snap_wood_walnut_original_price
            snap_wood_walnut_discounted_price
            snap_shadow_box_aluminum_original_price
            snap_shadow_box_aluminum_discounted_price
            snap_shadow_box_charcoal_original_price
            snap_shadow_box_charcoal_discounted_price
          }
        }
      }
    }
  `)
  const prices = contentfulFrame2Price.edges[0].node

  const styleConfig = {
    Wood: {
      image: {
        Walnut: woodWalnut,
        Birch: woodBirch,
      },
      snapImage: {
        Walnut: wood,
        Birch: wood,
      },
      snapFrameIndividualImage: {
        Walnut: individualWoodWalnut,
        Birch: individualWoodBirch,
      },
      prices: {
        Walnut: {
          originalPrice: prices.wood_walnut_original_price,
          discountedPrice: prices.wood_walnut_discounted_price,
          discountBubble: prices.wood_walnut_discount_bubble,
        },
        Birch: {
          originalPrice: prices.wood_birch_original_price,
          discountedPrice: prices.wood_birch_discounted_price,
          discountBubble: prices.wood_birch_discount_bubble,
        },
      },
      snapPrices: {
        Walnut: {
          originalPrice: prices.snap_wood_walnut_original_price,
          // discountedPrice: prices.snap_wood_walnut_discounted_price,
          discountedPrice: 'NONE',
        },
        Birch: {
          originalPrice: prices.snap_wood_birch_original_price,
          // discountedPrice: prices.snap_wood_birch_discounted_price,
          discountedPrice: 'NONE',
        },
      },
      defaultPrices: {
        originalPrice: prices.wood_walnut_original_price,
        discountedPrice: prices.wood_walnut_discounted_price,
        discountBubble: prices.wood_walnut_discount_bubble,
      },
      snapDefaultPrices: {
        originalPrice: prices.snap_wood_walnut_original_price,
        // discountedPrice: prices.snap_wood_walnut_discounted_price,
        discountedPrice: 'NONE',
      },
      description: 'Warm, natural hardwood.',
      basePrice: 199.99,
      snapBasePrice: 29.99,
      snapDiscountedPrice: 'NONE',
      snapPromo: 'Free',
      oos: true,
    },
    Classic: {
      image: {
        Black: classicBlack,
        White: classicWhite,
        Sage: classicSage,
        Lagoon: classicLagoon,
      },
      snapImage: {
        Black: classic,
        White: classic,
        Sage: classic,
        Lagoon: classic,
      },
      snapFrameIndividualImage: {
        Black: individualClassicBlack,
      },
      prices: {
        Black: {
          originalPrice: prices.classic_black_original_price,
          discountedPrice: prices.classic_black_discounted_price,
          discountBubble: prices.classic_black_discount_bubble,
        },
        White: {
          originalPrice: prices.classic_white_original_price,
          discountedPrice: prices.classic_white_discounted_price,
          discountBubble: prices.classic_white_discount_bubble,
        },
        Sage: {
          originalPrice: prices.classic_sage_original_price,
          discountedPrice: prices.classic_sage_discounted_price,
          discountBubble: prices.classic_sage_discount_bubble,
        },
        Lagoon: {
          originalPrice: prices.classic_lagoon_original_price,
          discountedPrice: prices.classic_lagoon_discounted_price,
          discountBubble: prices.classic_lagoon_discount_bubble,
        },
      },
      snapPrices: {
        Black: {
          originalPrice: prices.snap_classic_black_original_price,
          // discountedPrice: prices.snap_classic_black_discounted_price,
          discountedPrice: 'NONE',
        },
        White: {
          originalPrice: prices.snap_classic_white_original_price,
          // discountedPrice: prices.snap_classic_white_discounted_price,
          discountedPrice: 'NONE',
        },
        Sage: {
          originalPrice: prices.snap_classic_sage_original_price,
          // discountedPrice: prices.snap_classic_sage_discounted_price,
          discountedPrice: 'NONE',
        },
        Lagoon: {
          originalPrice: prices.snap_classic_lagoon_original_price,
          // discountedPrice: prices.snap_classic_lagoon_discounted_price,
          discountedPrice: 'NONE',
        },
      },
      defaultPrices: {
        originalPrice: prices.classic_black_original_price,
        discountedPrice: prices.classic_black_discounted_price,
        discountBubble: prices.classic_black_discount_bubble,
      },
      snapDefaultPrices: {
        originalPrice: prices.snap_classic_black_original_price,
        // discountedPrice: prices.snap_classic_black_discounted_price,
        discountedPrice: 'NONE',
      },
      description: 'Elegant & versatile.',
      basePrice: 169.99,
      snapBasePrice: 14.99,
      snapDiscountedPrice: 'NONE',
      snapPromo: 'Free',
      oos: true,
    },
    Cover: {
      image: {
        Black: coverBlack,
        White: coverWhite,
      },
      snapImage: {
        Black: cover,
        White: cover,
      },
      snapFrameIndividualImage: {
        Black: individualCoverBlack,
      },
      prices: {
        Black: {
          originalPrice: prices.cover_black_original_price,
          discountedPrice: prices.cover_black_discounted_price,
          discountBubble: prices.cover_black_discount_bubble,
        },
        White: {
          originalPrice: prices.cover_white_original_price,
          discountedPrice: prices.cover_white_discounted_price,
          discountBubble: prices.cover_white_discount_bubble,
        },
      },
      snapPrices: {
        Black: {
          originalPrice: prices.snap_cover_black_original_price,
          // discountedPrice: prices.snap_cover_black_discounted_price,
          discountedPrice: 'NONE',
        },
        White: {
          originalPrice: prices.snap_cover_white_original_price,
          // discountedPrice: prices.snap_cover_white_discounted_price,
          discountedPrice: 'NONE',
        },
      },
      defaultPrices: {
        originalPrice: prices.cover_black_original_price,
        discountedPrice: prices.cover_black_discounted_price,
        discountBubble: prices.cover_black_discount_bubble,
      },
      snapDefaultPrices: {
        originalPrice: prices.snap_cover_black_original_price,
        // discountedPrice: prices.snap_cover_black_discounted_price,
        discountedPrice: 'NONE',
      },
      description: 'Borderless & bold.',
      basePrice: 169.99,
      snapBasePrice: 14.99,
      snapDiscountedPrice: 'NONE',
      snapPromo: 'Free',
      oos: true,
    },
    'Shadow Box': {
      image: {
        Charcoal: shadowBoxCharcoal,
        Aluminum: shadowBoxAluminum,
      },
      snapImage: {
        Charcoal: shadowBox,
        Aluminum: shadowBox,
      },
      snapFrameIndividualImage: {
        Aluminum: individualShadowBoxAluminum,
      },
      prices: {
        Charcoal: {
          originalPrice: prices.shadow_box_charcoal_original_price,
          discountedPrice: prices.shadow_box_charcoal_discounted_price,
          discountBubble: prices.shadow_box_charcoal_discount_bubble,
        },
        Aluminum: {
          originalPrice: prices.shadow_box_aluminum_original_price,
          discountedPrice: prices.shadow_box_aluminum_discounted_price,
          discountBubble: prices.shadow_box_aluminum_discount_bubble,
        },
      },
      snapPrices: {
        Charcoal: {
          originalPrice: prices.snap_shadow_box_charcoal_original_price,
          // discountedPrice: prices.snap_shadow_box_charcoal_discounted_price,
          discountedPrice: 'NONE',
        },
        Aluminum: {
          originalPrice: prices.snap_shadow_box_aluminum_original_price,
          // discountedPrice: prices.snap_shadow_box_aluminum_discounted_price,
          discountedPrice: 'NONE',
        },
      },
      defaultPrices: {
        originalPrice: prices.shadow_box_aluminum_original_price,
        discountedPrice: prices.shadow_box_aluminum_discounted_price,
        discountBubble: prices.shadow_box_aluminum_discount_bubble,
      },
      snapDefaultPrices: {
        originalPrice: prices.snap_shadow_box_aluminum_original_price,
        // discountedPrice: prices.snap_shadow_box_aluminum_discounted_price,
        discountedPrice: 'NONE',
      },
      description: 'Sleek & modern aluminum.',
      basePrice: 199.99,
      snapBasePrice: 29.99,
      snapDiscountedPrice: 'NONE',
      snapPromo: 'Free',
      oos: true,
    },
  }

  return styleConfig
}
