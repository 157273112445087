import styled from 'styled-components'
import { Link } from 'gatsby'
import { CtaContainer } from 'src/components/FrameProductForm/styles'
import { breakpoints } from '../../utils/styles/global-styles'
import { ProductGridRight } from '../FrameProductPage/styles'

export const Container = styled.div`
  width: 90%;
  margin: auto;
  text-align: center;
  margin-bottom: 48px;
  display: ${(props) => (props.showOnMobile ? 'none' : 'block')};
  @media (max-width: ${breakpoints.m}px) {
    margin: 32px auto;
    display: ${(props) => (props.showOnMobile ? 'block' : 'none')};
  }
`

export const Header = styled.h1`
  font-size: 32px;
  line-height: 40px;
  margin: 16px 0px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 28px;
    line-height: 36px;
    margin: 6px 0px;
  }
  @media (max-width: 400px) {
    font-size: 25px;
  }
`
export const Text = styled.p`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.24px;
  max-width: 550px;
  margin: auto;
  @media (max-width: 400px) {
    max-width: 100%;
    font-size: 18px;
  }
`
export const ProductForm = styled.div`
  padding-bottom: 64px;
  @media (max-width: ${breakpoints.s}px) {
    padding-bottom: 40px;
  }
`
export const StyleSelectionContainer = styled.div`
  overflow-x: auto;
  white-space: nowrap;
  margin-top: 10px;
  padding-bottom: 16px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`
export const RightGrid = styled(ProductGridRight)`
  padding: ${(props) => (props.plp ? '0%' : props.freeSnap ? '32px 0px 16px' : '0% 0% 0% 5%')};
`
export const CTAContainer = styled(CtaContainer)`
  display: ${(props) => (props.plp ? 'none' : 'block')};
  @media (max-width: ${breakpoints.s}px) {
    margin-right: 5%;
  }
`
