import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'
import { ButtonContainer } from 'src/components/FrameProductForm/styles'
import { StyleText } from '../../templates/CalendarProductPage/styles'

export const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
`
export const PopupBox = styled.div`
  background-color: ${(props) => props.theme.cream};
  padding: 20px;
  border-radius: 8px;
  width: 85%;
  max-width: 750px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  @media (max-width: ${breakpoints.m}px) {
    padding: 16px;
  }
`
export const ClosePopupButton = styled.button`
  margin-top: 20px;
  padding: 10px 0px;
  font-size: 16px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  @media (max-width: ${breakpoints.m}px) {
    text-align: center;
    margin: 8px auto 0px;
    width: 100%;
  }
`
export const CloseButton = styled.span`
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 18px;
  cursor: pointer;
  @media (max-width: ${breakpoints.s}px) {
    top: 12px;
    right: 12px;
  }
`
export const Heading = styled.h2`
  color: ${(props) => props.theme.blueDark};
  font-family: 'P22MackinacProBold';
  margin: 32px 0px 8px;
  line-height: 1.2;
  @media (max-width: ${breakpoints.m}px) {
    text-align: center;
    max-width: 90%;
    margin: 32px auto 0px;
    font-size: 24px;
  }
  @media (max-width: ${breakpoints.s}px) {
    line-height: 1;
    font-size: 22px;
    margin: 8px 0px 8px;
  }
`
export const Text = styled.p`
  line-height: 1.2;
  font-family: 'FilsonPro';
  b {
    font-family: 'FilsonProBold';
  }
  @media (max-width: ${breakpoints.m}px) {
    text-align: center;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 14px;
  }
`
export const Recommended = styled.p`
  font-family: 'FilsonProBold';
  font-size: 18px;
  margin-bottom: 8px;
  @media (max-width: ${breakpoints.s}px) {
    margin: -8px 0px;
  }
`
export const Close = styled.p`
  color: ${(props) => props.theme.coral};
  text-decoration: underline;
  @media (max-width: ${breakpoints.l}px) {
    margin: auto;
  }
`
export const Flexbox = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: baseline;
`
export const Style = styled.p`
  font-family: 'P22MackinacProMedium';
  margin: 0px 0px -8px;
`
export const Price = styled(StyleText)`
  text-decoration: ${(props) => (props.strikethough ? 'line-through' : 'none')};
  color: ${(props) => (props.discount ? props.theme.blueDark : '#4f4e4e')};
  font-size: ${(props) => (props.discount ? '14px' : '16px')}; ;
`
export const CtaContainer = styled(ButtonContainer)`
  @media (max-width: ${breakpoints.l}px) {
    margin: 8px auto 0px;
    text-align: center;
  }
`
export const Delay = styled.p`
  font-size: 14px;
  margin-top: 0px;
  color: ${(props) => props.theme.blueDark};
  font-family: 'FilsonProBold';
  line-height: 1.2;
  @media (max-width: ${breakpoints.m}px) {
    text-align: center;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 12px;
  }
`
