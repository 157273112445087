import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'

import { breakpoints, ContentContainer } from '../utils/styles/global-styles'

import SEO from '../components/seo'

import Cart from '../components/Cart'
import Analytics from '../utils/Analytics'

class CartPage extends React.Component {
  componentDidMount() {
    Analytics.track('Viewed Cart')
  }

  render() {
    const { t } = this.props

    return (
      <>
        <SEO
          title={t('Your Shopping Cart')}
          path="/cart/"
          description={t('Purchase a Skylight Photo Frame to send love effortlessly.')}
        />
        <Section>
          <Container>
            <Header>
              <Trans>Your Shopping Cart</Trans>
            </Header>
          </Container>
          <Cart />
        </Section>
      </>
    )
  }
}

CartPage.propTypes = {
  t: PropTypes.func,
}

export default withTranslation()(CartPage)

const Section = styled.div`
  background-color: ${(props) => props.theme.cream};
`
const Header = styled.h1`
  color: #444444;
  font-weight: 300;
  margin: 0px;
  @media (max-width: 768px) {
    font-size: 30px;
    text-align: center;
  }
`
const Container = styled(ContentContainer)`
  margin: 0px auto;
  padding: 20px 0px 0px;
  @media (max-width: ${breakpoints.s}px) {
    padding: 10px 0px 0px;
  }
`

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["common", "cart"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
